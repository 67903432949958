.configurable-tooltip-container {
  position: relative;
  display: inline-block;
}

.configurable-tooltip {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: -180%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 0.9rem;
}

.configurable-tooltip-container.isTooltipVisible .configurable-tooltip {
  visibility: visible;
  opacity: 1;
}
