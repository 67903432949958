//
// Form-Upload
//

/* Dropzone */
.upload-file-dropzone {
  min-height: 230px;
  border: 2px dashed black;
  background: #f1f1f1;
  border-radius: 6px;

  .dz-message {
    font-size: 24px;
    width: 100%;
    margin: 3rem 0;
  }
}

.upload-file-dropzone .dz-message {
  margin: 0;
}

.delete-file-icon {
  background-color: #fdf0f0;
  font-size: 22px;
  border-radius: 2px;
  padding: 4px 8px;
  color: $danger;
}
