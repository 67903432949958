.viewerContainer {
  height: 60vh;
  border: 1px solid #dadada;
  overflow-y: auto;
  background-color: lightgrey;
  margin-right: 1rem;
}

@media (display-mode: fullscreen) {
  .viewerContainer {
    height: 90vh;
  }
}

.pdfContainer {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: center;
}

.pdfPageCanvas {
  position: relative;
  margin: auto;
}
