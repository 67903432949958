.users-dropdown-filter {
  background-color: white;
  border-color: darkgray;
  color: $gray-700;
  height: 30px;
  padding: 0px;
  padding-left: 1rem;
  padding-right: 0.5rem;
  padding-top: 2px;
  width: 80%;
}
