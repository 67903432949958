.aa-list-status-failed {
  color: $danger !important;
}

.aa-list-review-progress {
  height: 25px;
  width: 25px;
}

.planTableStatusColumn {
  min-width: 100px;
}

.edit-document-name-icon {
  font-size: 16px;
  text-align: center;
  margin-top: -3px;
}

.download-file-icon {
  margin-left: 15px;
}

.uploaded-by-label {
  font-size: small;
}

.checkbox-agreement-column {
  font-size: medium;
}
