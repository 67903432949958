.agreements-toolbar {
  min-height: 110px;
}

.cursor-pointer {
  cursor: pointer;
}

.green-check {
  color: $green-500;
}

.small-spinner {
  height: 15px;
  width: 15px;
  color: $pdai-teal-blue;
}

.agreements-list {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.agreements-row {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}

.agreements-cell {
  padding: 8px;
  text-align: left;
  box-sizing: border-box;
  &:nth-child(1) {
    flex: 1 0 100%;
  }
  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4) {
    padding-left: 35px;
    flex: 1 0 33%;
  }
}

/* Optional: Style the first row (header) differently */
.aagreements-row:first-child .agreements-cell {
  font-weight: bold;
  background-color: #f0f0f0;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
}

.checkbox-agreement-container {
  display: flex;
}

.checkbox-agreement-input {
  font-size: 16px;
  margin-top: 0;
}

.plan-actions-size {
  width: 18% !important;
}

.tooltip-text {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
  opacity: 1;
}

.link-button {
  /* Remove default button styles */
  background: none;
  border: none;
  padding: 0;
  cursor: pointer; /* Indicate it's clickable */

  /* Style it like a link */
  color: $primary; /* Or your preferred link color */
  text-decoration: underline;

  /* Optional: Add hover effect */
  &:hover {
    text-decoration: none;
  }

  /* Optional: Remove focus outline */
  &:focus {
    outline: none;
  }
}

.link-button-error {
  @extend .link-button;
  color: $danger;
}

.modal-body-feedback {
  width: 100%;
  border-color: lightgray;
  color: gray;
}

.answer-column {
  width: 60%;
}

.logo-temp {
  font-size: x-large;
  margin-top: 25px;
  color: lightgrey;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

/* Media query for larger screens */
@media (min-width: 1168px) {
  .agreements-cell {
    &:nth-child(1) {
      flex: 1 0 50%;
    }
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      flex: 1 0 13%;
    }
  }
}

.provision-popover {
  z-index: 9999;
  background-color: $gray-400;

  &.arrow {
    background-color: $gray-400;
  }
}
/* Style the popover content */
.feedback-popover-body {
  background-color: $gray-400;
  padding: 20px;
  width: 280px;
}

.feedback-popover-header {
  background-color: $gray-400;
  border: 0;
}

/* Style the original value container */
.feedback-original-value-container {
  white-space: pre-wrap;
  overflow: auto;
  max-height: 100px;
}

.logo-container-login {
  display: flex; /* Enable flexbox for the container */
  justify-content: center; /* Center the logo horizontally */
  align-items: center; /* Center the logo vertically */
  width: 100%; /* Make the container take the full width */
  height: 100px; /* Set a fixed height for the container (adjust as needed) */
  overflow: hidden; /* Hide any part of the logo that overflows */
}

.logo-login {
  max-width: 100%; /* Ensure the logo doesn't exceed the container width */
  max-height: 100%; /* Ensure the logo doesn't exceed the container height */
}

/* Styles for the not-collapsed state */
body:not(.vertical-collpsed) {
  .pdai-not-collapsed {
    display: block;
  }
  .pdai-collapsed {
    display: none;
  }
  .beta-logo {
    color: white;
    font-family: 'Poppins';
    margin-left: 80%;
    font-weight: 900;
    font-size: 14px;
    padding: 2px 5px;
    border: 2px dashed white;
  }
}

/* Styles for the collapsed state */
body.vertical-collpsed {
  .pdai-not-collapsed {
    display: none;
  }
  .pdai-collapsed {
    display: block;
    padding: 0px;
  }
  .beta-logo {
    color: white;
    font-family: 'Poppins';
    font-weight: 900;
    font-size: 9px;
    padding: 2px 5px;
    margin-bottom: 6px;
    border: 1px dashed white;
  }
}

.login-divider {
  border-top-width: 1px;
  border-top-color: $pdai-teal-blue;
  border-top-style: solid;
  margin: 30px 25% 50px 25%;
}
