.active-tab {
  font-weight: 900 !important;
  color: black !important;
}

.regular-tab {
  font-weight: 300 !important;
  color: black !important;
}
.planListRightPanel {
  width: 100%;
  margin-left: 1rem;
}

.plans-status-list {
  color: $gray-200 !important;
  a {
    display: block;
    color: $gray-200;
    line-height: 24px;
    padding: 8px 0px;
  }
}

.plansOptionsButtons {
  margin-left: 0.75rem;
}
