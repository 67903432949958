.summary-gemini h2 {
  font-size: 20px;
}

.summary-gemini h3 {
  font-size: 16px;
  text-decoration: underline;
  padding-top: 10px;
}

.summary-gemini h4 {
  font-weight: bold;
  font-size: 13px;
}
