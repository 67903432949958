.clipboard-copied-toast {
  background-color: $gray-400;
  color: black;
  border-radius: 5px;
  width: 89px;
  height: 30px;
  padding: 4px 6px !important;
}

.copy-clipboard-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: white;
  color: black;
  border: none;
  font-size: 16px;
}
.copy-clipboard-button:hover {
  background-color: white;
  font-weight: 600;
  color: $gray-700;
}
.copy-clipboard-button:active {
  background-color: $gray-600 !important;
}
